











































































































import Vue from 'vue';

import Field from '@/components/Field.vue';
import SelectField from '@/components/SelectField.vue';
import Switcher from '@/components/Switcher.vue';

import PostbackTree from '@/components/Postback/Tree.vue';
import ExternalUsers from '@/components/ExternalUsers.vue';

import RCP from '@/api/RCP';
import Validator from '@/helpers/validator';
import { ErrorResponse } from '@/types/Errors';

import inParams from '@/helpers/const/inParams';
import actions from '@/helpers/const/actions';
import channel from '@/helpers/const/channel';

import swal from 'sweetalert2';

const actionsModel = {} as any;

Object.keys(actions).forEach((key) => {
    actionsModel[key] = false;
});

export default Vue.extend({
    name: 'AddPartner',
    data() {
        const inParameters = {} as any;
        const inParametersRules = {} as any;
        const parametersErrors = {} as any;

        inParams.forEach((name: any) => {
            inParameters[name] = '';
            inParametersRules[name] = 'min:1|max:255';
            parametersErrors[name] = '';
        });

        return {
            externalUser: {} as any,
            check: false,
            form: {
                project_id: 0,
                code: '',
                additional_code: '',
                title: '',
                description: '',
                cost: null as any,
                archive: false,
                target_events: [],
                post_back_actions: [],
                channel: ''
            },
            inParameters,
            inParametersRules,
            parametersErrors,
            formRules: {
                project_id: 'required',
                code: 'required|min:1|max:255',
                additional_code: 'min:1|max:255',
                title: 'required|min:1|max:255',
                cost: 'required'
            },
            errors: {
                project_id: '',
                code: '',
                additional_code: '',
                title: '',
                cost: ''
            },
            post_back_actions: [] as any,
            active: true,
            projects: {} as any,
            actions,
            actionsModel,
            request: false,
            formRequest: false,
            checkWatcher: () => { return; },
            checkParamsWatcher: () => { return; },
            options: {
                channel
            }
        };
    },
    mounted() {
        this.getFilters();

        Object.keys(this.actionsModel).forEach((key: any) => {
            this.actionsModel[key] = false;
        });

        this.checkWatcher = this.$watch('form', () => {
            this.check = true;
            this.checkParamsWatcher();
            this.checkWatcher();
        }, {
            deep: true
        });

        this.checkParamsWatcher = this.$watch('inParameters', () => {
            this.check = true;
            this.checkParamsWatcher();
            this.checkWatcher();
        }, {
            deep: true
        });
    },
    methods: {
        getFilters() {
            return new Promise((resolve) => {

                this.projects = this.$store.state.filters.projects;

                if (this.projects.length > 0) {
                    this.form.project_id = this.projects[0].id;
                }

                resolve();
            });
        },
        validate(submit = true) {
            if (!this.request) {
                const validate = new Validator(this.form, this.formRules).make();
                const paramsValidate = new Validator(this.inParameters, this.inParametersRules).make();

                this.errors = validate.errors;
                this.parametersErrors = paramsValidate.errors;

                if (validate.isValid && paramsValidate.isValid && submit) {
                    this.addPartner();
                } else {
                    this.formRequest = true;
                }
            }
        },
        addPartner() {
            this.request = true;
            this.check = false;

            const form = {} as any;

            Object.keys(this.form).forEach((key: any) => {
                if (this.form[key]) {
                    form[key] = this.form[key];
                }
            });

            const inParameters = [] as any;

            Object.keys(this.inParameters).forEach((name: any) => {
                if (this.inParameters[name]) {
                    inParameters.push({
                        name,
                        value: this.inParameters[name]
                    });
                }
            });

            form.in_parameters = inParameters;

            form.post_back_actions = this.post_back_actions.map(({action, send_type, uri, parameters}) => {
                const returned = {
                    action,
                    send_type,
                    uri
                } as any;

                let params = [] as any;

                if (parameters.length > 0) {
                    params = parameters.map(({name, default_value, template}) => {
                        return {
                            name,
                            default_value: default_value || null,
                            template: template || null
                        };
                    });
                }

                returned.parameters = params;

                return returned;
            });

            const eventsArr = [] as any;

            Object.keys(this.actionsModel).forEach((key: string) => {
                if (this.actionsModel[key]) {
                    eventsArr.push({
                        action: key
                    });
                }
            });

            form.target_events = eventsArr;

            RCP({
                method: 'Partner.add',
                params: { ...form, cost: +form.cost },
                id: 'addPartner'
            }).then((response) => {
                if (this.externalUser && this.externalUser.id && response.partner_id) {
                    RCP({
                        method: 'UserExternalAssoc',
                        id: 'UserExternalAssoc',
                        params: {
                            partner_id: response.partner_id,
                            user_external_id: this.externalUser.id
                        }
                    }).then(() => {
                        swal.fire({
                            icon: 'success',
                            title: 'Успешно',
                            text: 'Партнер успешно добавлен',
                            confirmButtonText: 'Ок',
                            confirmButtonColor: '#605ab9'
                        });
                        this.$router.push('/partners');
                    }).catch((error: ErrorResponse) => {
                        if (error.show) {
                            swal.fire({
                                icon: 'error',
                                title: 'Ошибка',
                                text: error.message,
                                confirmButtonText: 'Ок',
                                confirmButtonColor: '#605ab9'
                            });
                        }

                        this.request = false;
                    });
                } else {
                    swal.fire({
                        icon: 'success',
                        title: 'Успешно',
                        text: 'Партнер успешно добавлен',
                        confirmButtonText: 'Ок',
                        confirmButtonColor: '#605ab9'
                    });
                    this.$router.push('/partners');
                }
            }).catch((error: ErrorResponse) => {
                if (error.show) {
                    swal.fire({
                        icon: 'error',
                        title: 'Ошибка',
                        text: error.message,
                        confirmButtonText: 'Ок',
                        confirmButtonColor: '#605ab9'
                    });
                }

                this.request = false;
            });
        },
        checkChanges() {
            return new Promise((resolve) => {
                if (this.check) {
                    swal.fire({
                        title: 'Внесены изменения',
                        text: 'Вы уверены что хотите покинуть страницу не сохранив изменения?',
                        icon: 'warning',
                        showCloseButton: true,
                        showCancelButton: true,
                        confirmButtonColor: '#605ab9',
                        cancelButtonText: 'Перейти',
                        confirmButtonText: 'Остаться'
                    }).then((answer: any) => {
                        if (answer.dismiss === 'cancel') {
                            resolve();
                        }
                    });

                } else {
                    resolve();
                }
            });
        }
    },
    watch: {
        formRequest(val) {
            if (val) {
                setTimeout(() => {
                    const element = document.querySelector('.field--error');

                    if (element) {
                        element.scrollIntoView({ behavior: 'smooth', block: 'center'});
                    }
                });

                this.$watch('form', () => {
                    this.validate(false);
                }, {
                    deep: true
                });

                this.$watch('inParameters', () => {
                    this.validate(false);
                }, {
                    deep: true
                });
            }
        },
        active(val) {
            this.form.archive = !val;
        }
    },
    beforeRouteLeave(to, from, next: any) {
        this.checkChanges().then(next);
    },
    components: {
        Field,
        SelectField,
        Switcher,
        PostbackTree,
        ExternalUsers
    }
});
